import React from "react";
import { ReactComponent as Logo } from './home.svg';

function Header(props){
  return (
    <section className="home">
      <h1>
        <div onClick={props.setnavToggle}>
            <Logo />
        </div>
      </h1>
    </section>
  )
}

export default Header;