import React from "react";

function Footer(){
  return (
    <footer>
      <span>
        &copy; {new Date().getFullYear() } marcells.website; all rights reserved 
      </span>
      {/*
      &bull;
      <a href="facetime:me@marcells.website">
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/62/FaceTime_iOS.svg" />
      </a>
      */}
    </footer>
  )
}

export default Footer;