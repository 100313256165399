/*
  _____ _       _                 _    
 |  __ (_)     | |               | |   
 | |__) | _ __ | |__   ___   ___ | | __
 |  ___/ | '_ \| '_ \ / _ \ / _ \| |/ /
 | |   | | | | | |_) | (_) | (_) |   < 
 |_|   |_|_| |_|_.__/ \___/ \___/|_|\_\
                                       
 Cell 2019, 2023                   
*/

import React, { Component, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import _ from "underscore";
import App from './App.js'
import Modal from './Modal.js';
import API from './API.js';

class Index extends Component {

    constructor(props){
      super(props);
  
      this.state = {...props};
      //console.log('Index Props // ', props)
      //console.log('LOCATION // ', this.props.location)
      //console.log('BACKGROUND // ', this.props.background)
    }
    render = () => {
      return (
        <>
          <Routes location={this.props.background || this.props.location}>
            <Route exact path="/" element={<App board={this.props.books} navToggle={this.props.navToggle} setnavToggle={this.props.setnavToggle} />} />
            
            <Route 
              exact path="/:board" 
              element={
                <App 
                  board={this.props.books}
                  navToggle={this.props.navToggle} 
                  setnavToggle={this.props.setnavToggle}
                  focusPin={this.props.focusPin}
                  setfocusPin={this.props.setfocusPin}
                />}
            >
              <Route exact path=":pin" element={<Modal board={this.props.books} pin={this.props.focusPin}/>} />
            </Route>
  
          </Routes>
          {this.props.background && (
            <Routes>
              <Route path="/:board/:pin" element={<Modal board={this.props.books} pin={this.props.focusPin}/>} />
            </Routes>
          )}
        </>
      )
    }
  }
  
  function IndexWrapper() {
    const location = useLocation();
    const background = location.state && location.state.background;
    
    //console.log('router init', location, background)
    const [books, setBooks] = useState([]);
    const [focusPin, setfocusPin] = useState({});
  
    //Nav Toggle
    let initialToggleVal;
  
    
  
    
    if(localStorage.getItem('navToggle') === null){
      localStorage.setItem('navToggle', true);
    }
  
    const [navToggle, setnavToggle] = useState(JSON.parse(localStorage.getItem('navToggle')));
    
    const toggleSwitch = (val) => {
      if(val === false){
        //alert(val)
        setnavToggle(false);
        localStorage.setItem('navToggle', false);
        //console.log('TOGGLE SWITCH :: FALSE');
        return;
      }
  
      if(navToggle === true) { 
        setnavToggle(false);
        localStorage.setItem('navToggle', false);
        //console.log('TOGGLE SWITCH :: FALSE');
      } else {
        setnavToggle(true);
        localStorage.setItem('navToggle', true);
        //console.log('TOGGLE SWITCH :: TRUE');
      }
    }
    const getBooks = async () => {
      
      const request = fetch(API().apiURL);
      const response = await request;
      const parsed = await response.json();
      
      setBooks(parsed);
      
      //console.log('Parsed //',parsed)
    }
  
    useEffect(() => {
      getBooks();
    },[]);
  
    //setnavToggle(true);
  
  
  
    return (
      <Index 
        books={books} 
        background={background}
        location={location} 
        
        navToggle={navToggle} 
        setnavToggle={toggleSwitch} 
        focusPin={focusPin}
        setfocusPin={setfocusPin}
      >
      </Index>
      
    )
  }

  export default IndexWrapper;