import React, { Component, useState, useEffect } from "react";
import PinWrapper from './Pin.js';
import PinterestGrid from 'rc-pinterest-grid';
import _ from "underscore";
import $ from "jquery";
/***
 * SRE -> Not SRE Ready
 * Modify Board component to use root instead of window, or dynamically load a placeholder
 * REMINDER -> this was for SEO purposes, so I can negate and remove the bullshit
 */

const BoardWrapper = (props) => {
  const [localPins, setLocalPins] = useState([]);
  const [renderedPins, setRenderedPins] = useState([]);
  
  useEffect(() => {
    //console.log('board effect',props);
    setLocalPins(props.pins);
    setRenderedPins(props.pins.slice(0,10));
  },[props.pins]);

  return <Board 
            {...props} 
            localPins={localPins} 
            setLocalPins={setLocalPins} 
            renderedPins={renderedPins} 
            setRenderedPins={setRenderedPins} 
        />;
}

class Board extends Component {
    constructor(props){
      super(props);
      
      //console.log('Board Props', props)
      this.state = {
        board: this.props.board,
        scroller: 0
      }
      
      this.pins = this.props.pins;
      this.pageIndex = 2;
      this.handleScroll = this.handleScroll.bind(this);

      //this.props.setPins(this.props.pins.slice(0,15));
    }

    paginate = (array, page_size, page_number) => {
      --page_number; // because pages logically start with 1, but technically with 0
      return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }
    
    loadMorePins = (scrollPos) => {
      let newPinstoRender = this.paginate(this.props.localPins, 10, this.pageIndex);
      this.pageIndex+= 1;

      this.props.setRenderedPins([...this.props.renderedPins, ...newPinstoRender]);
      
      //console.log('already rendered',this.props.renderedPins)
      //console.log('new pins to render',newPinstoRender);
      /*
      localPins={localPins} 
      setLocalPins={setLocalPins} 
      renderedPins={renderedPins} 
      setRenderedPins={setRenderedPins} 
      */

      /*
      console.log('newPinstoRender', newPinstoRender);
      console.log('this.props.localPins', this.props.localPins);
      console.log('this.props.pins', this.props.pins);
      console.log('this.pins', this.pins);
      */

      //this.props.setPins([ this.props.pins[2], this.props.pins[2],this.props.pins[2], this.props.pins[2], this.props.pins[2], this.props.pins[2], this.props.pins[2]]);
      
      
      //this.setState({pageIndex: this.state.pageIndex += 1});
      //console.log(this.state.pageIndex);
      //this.render();
      //console.log('pins from props // ', this.props.pins);
      //console.log('pins from instance fullPinSet [local] // ', this.fullPinSet);
      //pinsToRender: this.props.pins.slice(0,20),
      //this.props.setPins([]);

      //this.setState({pinsToRender: this.state.pins.slice(20,30)})
      /**
       * var loadNewPins = function() {
	 _.each(paginate(newpins, 20, page), function(pin){
       	//console.log(pin);
			wall.appendBlock(pinTMPL(pin));
			//wall.fitWidth();
		});

       wall.container.find('.pin img').load(function() {
			wall.fitWidth();
		});

		wall.container.find('video').load(function() {
			wall.fitWidth();
		});
       page++;
}

var paginate = function(array, page_size, page_number) {
  --page_number; // because pages logically start with 1, but technically with 0
  return array.slice(page_number * page_size, (page_number + 1) * page_size);
}
{this.props.pins.slice(0,15).map(obj => (
       */
    }

    handleScroll = (event) => {
      
      let lastKnownScrollPosition = 0;
      let ticking = false;
      let self = this;
      let scroller = document.querySelector("#root");

      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {

          var s = $(window).scrollTop(),
          d = $(document).height(),
          c = $(window).height();
    
          var scrollPercent = (s / (d - c)) * 100;
          //console.log('scroll percent', scrollPercent)
          //console.log('scrollPos', scrollPos);
          
          //console.log('jQuery', $(window).scrollTop() , $(window).height() , $(window).scrollTop() + $(window).height(), $(document).height() )
          if($(window).scrollTop() + $(window).height() + 5 > $(document).height()){
            //alert('scrolled to bottom, load more plz')
            var tempScrollTop = $(window).scrollTop();

            self.loadMorePins([lastKnownScrollPosition,scroller.scrollTop]);

            //$(window).scrollTop($(window).scrollTop() + $(window).height() - 10%);
            //window.scroll(0,$(document).height() - 125)


              window.scroll({
                top: $(window).scrollTop() - 50,
                behavior: "smooth",
              });
            
            
            
          }
          ticking = false;
        });
        
        ticking = true;
      }

    }
    
    componentDidMount() {
      document.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
      document.removeEventListener("scroll", this.handleScroll);
      //alert('The component is going to be unmounted');
    }
  
    
    render = () => {

      //console.log('Board Props // ',this.props)
      return (
        <main>
          <h2 className="nav" onClick={this.props.setnavToggle}>{/*} Board:*/} {this.state.board}</h2>
          <PinterestGrid
            columns={6}               // how many columns in one row
            columnWidth={200}         // width of each block
            gutterWidth={40}          // horizontal gutter between each block
            gutterHeight={40}         // vertical gutter between each block
            responsive={true}
            >
              {/* onClick={obj.type === 'video' ? null : this.prependItem} */}
            
            {this.props.renderedPins.map(obj => {
              //console.log(obj);
              return (
                <>
                {/*console.log(obj)*/}
                <PinWrapper setfocusPin={this.props.setfocusPin} {...obj} parent={this} key={obj.src}></PinWrapper>
                </>
              )}
            )}
          </PinterestGrid>
        </main>
      );
    }
  }

  export default BoardWrapper;