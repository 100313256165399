//ToDo: CMS Development
//console.log(process.env);

//let flag = 'dev';
let flag = null;

function API(){
    if(flag === 'dev'){
        return {
            apiURL: "http://localhost/api/books",
            imageBase: "http://192.168.1.113:1234/%23books/"
        };
    } else {
        return {
            apiURL: "https://cms.marcells.website/api/books",
            imageBase: "https://cms.marcells.website/%23books/"
        };
    }
}

export default API;

//console.log(process.env.ENVIRONMENT);

