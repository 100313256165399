import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import PinWrapper from './Pin.js';
import API from './API.js';
import _ from 'underscore';

const Modal = (props) => {
    const { board, pin } = useParams();
    const location = useLocation();
    const [focusPin, setFocuspin] = useState({});

    let boardInfocus = _.findWhere(props.board.books, {hash: encodeURIComponent(board)});

    /*
    console.log('MODAL boardInfocus', boardInfocus);
    console.log('MODAL board', board);
    console.log('MODAL prop board', props.board.books);
    console.log('MODAL pin', pin);
    console.log('MODAL location', location);
    */

    //alert('Render Modal Instead');
  
    const fetchFocuspin = async () => {
      const request = fetch(`${API().apiURL}/${encodeURIComponent(board)}`);
      const response = await request;
      const res = await response.json();

      
      //console.log(`the focus pin ${pin} is somewhere in here`, res);
      let focusPin = _.findWhere(res.pins, {name: pin});

      setFocuspin(focusPin);

      //console.log('here it is', focusPin);

    }

    useEffect(() => {
      fetchFocuspin();
    },[pin]);
    
    const urlBase = API().imageBase;
  console.log(focusPin);
  let newSrc = urlBase + encodeURIComponent(board) + '/' + encodeURIComponent(focusPin.name);
  focusPin.src = newSrc;
      
    return (
      <>
      <div id="modal-wrapper">
        <Link to="./..">
          <div id="modal">
            {/*JSON.stringify(focusPin)*/}
            <h2 className="nav">&larr;</h2>
            <PinWrapper {...focusPin}></PinWrapper>
            <p>{/*Description of the Item*/}</p>
          </div>
        </Link>
      </div>
      </>
    )
}

export default Modal;