//ToDo: Skeletal Loading/Lazy Loading -> https://stackoverflow.com/questions/56948061/show-a-react-skeleton-loader-until-a-image-load-completely

/**
 * ToDo: Individual Pin Route
 * if pin stub exist in URL, get the current board, sort pins by ID, render Modal with individual Pin
 */

/**
 * ToDo: Render Parent Routes on Fresh 
 * if pin stub exist in URL, get the current board, sort pins by ID, render Modal with individual Pin
 */


import React, { Component, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {arrayMoveImmutable} from 'array-move';
import _ from 'underscore'


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  

const PinWrapper = (props) => {
    const location = useLocation();
    const [localPinState, setLocalPinState] = useState([]);
    const [markdownText, setmarkdownText] = useState('');
    const [imageState, setImageState] = useState(false);
  
    //if Markdown
    useEffect(() => {
      if(
        props.src.includes('.md')
        ){
        //console.log('markdown pin', props.src);

        const fetchData = async () => {
          try {
              const response = await fetch(props.src);
              const md = await response.text();
              setmarkdownText(md);
              //console.log('markdown res', md);
          } catch (error) {
              //console.log("error", error);
          }
        };

        fetchData();

      }
    }, []);
    
    //console.log('pin props',props);
    return <Pin imageState={imageState} setimageState={setImageState} markdownText={markdownText} location={location} key={props.src} localPinState={localPinState} setLocalPinState={setLocalPinState}  {...props}/>
  }
  
  class Pin extends Component {
    constructor(props){
      super(props);
      
      let me = this;
      if(props.src.includes('.webm') || props.src.includes('.mp4')){

        //this.video = React.createRef();

        me.settVideoRef = element => {
          this.videoRef = element;

          //console.log('video ref set', this.videoRef)

          if(this.props.imageState === false && this.videoRef) {
            this.videoRef.oncanplaythrough = function() {
            //this.videoRef.loadeddata = function() {
              me.handleVideoLoaded();
              //console.log('oncanplaythrough', me.videoRef)
            };
          }

        };
      }

      this.state = {
        ...{props},
        videoBlob: null
      }

      this.props.setLocalPinState(this.props.files);
      
      //console.log('PIN PROPS', props);
      //console.log('PIN STATE', this.state);
    }

    openModal = () => {
      this.props.setfocusPin(this.props);
    }

    slideAlbum = () => {
      let newPinState = this.props.localPinState;
      let array2 = arrayMoveImmutable(newPinState, -1, 0);

      this.props.
      setLocalPinState(array2);
    }

    handleImageLoaded = () => {
      this.props.setimageState(true);
    }
    
    handleVideoLoaded = () => {
      this.props.setimageState(true);
      //console.log('video loaded', this.videoRef);
    }

    playVideo = (e) => {

      e.preventDefault();

      if (this.videoRef.paused) {
        this.videoRef.play();
      } else {
        this.videoRef.pause();
      }

    }
  
    render = () => {

      let self = this;

      //Album
      let directory = this.props.directory;

      if(directory === true) {
        //return null;
        return (
          <div className="album-wrapper">
          
          {/*console.log('album ', this.props)*/}

          {this.props.localPinState.slice(0,3).map((file, index) => {
            {
              let img = new Image();
              img.onload = function () {
                self.handleImageLoaded();
              }
              img.src = file.src;
            }
            return (
              <div className={index === 0 ? "album-item top" : "album-item"} onClick={this.slideAlbum} key={file.src}>
                {file.src.includes('.mp4') ||
                  file.src.includes('.webm') ? 
                  <>
                    <video className="album-img album-video" src={file.src} controls muted={true} />
                  </>
                  :
                  <>
                    {!this.props.imageState && <Skeleton height={200} width={200} />}
                    {this.props.imageState && <img className="album-img" src={file.src} />}
                  </>
                }
                <div className="gradient"></div>
              </div>
            )
          })}
          </div>
        )
      }

      //Text | Markdown
      let md = this.props.src.includes('.md');

      if(md) {
        //return null;
        return (
          <div className="text">
            <ReactMarkdown>
              {this.props.markdownText}
            </ReactMarkdown>
          </div>
        )
      }
      
      //Video
      let video = this.props.src.includes('.mp4') || this.props.src.includes('.webm');

      if(video){

        let videoID = _.uniqueId('video-');

        return (
          <>
            {!this.props.imageState && 
            <>
              <Skeleton height={200} width={200} />
              <video id={videoID} src={this.props.src} ref={this.settVideoRef} className="video-preloader"></video>
            </>
            }
            {this.props.imageState && 
            <>
              <Link
              key={`${this.props.src}`}
              to={{
                pathname: `${this.props.hash}`
                // This is the trick! This link sets
                // the `background` in location state.
              }}
              state={{ background: this.props.location }}
            >
              <div className="block"></div>
              <div class="play-btn" onClick={this.playVideo}>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                    <polygon class="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                    <path class="play-btn__svg" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                  </svg> 
                </a>
              </div>
              <video src={this.props.src} ref={this.settVideoRef} className="video-loaded" alt={this.props.label} preload="true" muted={true} />
            </Link>
            </>
            }
          </>
        );
      }

      //SVG
      let svg = this.props.src.includes('.svg');
      
      if(svg){
        //return null;
        let img = new Image();
        img.onload = function () {
          self.handleImageLoaded();
        }
        img.src = this.props.src;


        return (
          <Link
            key={`${this.props.src}`}
            to={{
              pathname: `${this.props.hash}`
              // This is the trick! This link sets
              // the `background` in location state.
            }}
            state={{ background: this.props.location }}
          >
            <figure className="svg image pin">
              {/*`${this.props.imageState}`*/}
              {!this.props.imageState && <Skeleton height={200} width={200} />}
              {this.props.imageState && <img src={this.props.src} alt={this.props.label} />}
              <figcaption>{this.props.label}</figcaption>
            </figure>
          </Link>
        )
      }

      //Video
      let audio = this.props.src.includes('.mp3') || 
                  this.props.src.includes('.ogg') ||
                  this.props.src.includes('.wav');

      if(audio){
        return null;
      }
      
        
      //Image | Default
      let defaultPin = () => {
        //return null;
        let img = new Image();
        img.onload = function () {
          self.handleImageLoaded();
        }
        img.src = this.props.src;


        return (
          <Link
            key={`${this.props.src}`}
            to={{
              pathname: `${this.props.hash}`
              // This is the trick! This link sets
              // the `background` in location state.
            }}
            state={{ background: this.props.location }}
          >
            <figure className="image pin">
              {/*`${this.props.imageState}`*/}
              {!this.props.imageState && <Skeleton height={200} width={200} />}
              {this.props.imageState && <img src={this.props.src} alt={this.props.label} />}
              <figcaption>{this.props.label}</figcaption>
            </figure>
          </Link>
        )
      }

      return defaultPin();
    }
  }

export default PinWrapper;