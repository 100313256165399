/**
 * ToDo -> API
 * -> if Thumbnail
 * -> if Meta
 * -> http URL
 * -> https URL
*/

/**
 * ToDo -> Clean Up
*/

/*
* -> ToDo -> NSFW
*/
/**
 * ToDo -> SVG Library, Fontawesome
*/

/**
 * ToDo -> Back to Top Button
*/

/*
* ToDo -> Load State -> Length of paginatatedPins > 0 else end message
*/

/*
* IG Login for Private Photos
*/

/*
* ToDo -> WebM, GifV, NSFW, <SecureIMG> -> Web ENC, w/ Today's Password + Master Password, Blurred Image
*/
/**
 * ToDo -> Single Pin Refactor
 * -> Render Pin
 * -> Click Outside Modal Exit
 * -> Escape Key Exit
 * -> Lock Scroll
 * -> (?) Higher Order Component
*/


/**
 * ToDo -> Video
 * -> Loop, Controls, Audio Toggle, playing state, mute
*/


/**
 * ToDo -> SVG
 * -> Skeleton Loading and FadeIN
*/

/**
 * ToDo -> UI/UX/CSS
 * -> Board Links Skeleton Image
*/

/**
 * ToDo -> Save Scroll POS in Router
 * -> Board Links Skeleton Image
*/

/**
 * ToDo -> Album
 * ->  Skeleton Loading
 * ->  Loading
 * -> Pagination for Thumbs
 * -> Modal Style
*/

import React, { useEffect, useState } from "react";
import { Link, useParams, Outlet } from "react-router-dom";
import Header from './Header.js';
import Footer from './Footer.js';
import Board from './Board.js';
import API from './API.js';
import './App.css';

function App(props){

  const { board } = useParams();
  const [pins, setPins] = useState([]);
  const [singlePin, setSinglePin] = useState({});

  const getPins = async () => {

    if(board === undefined) {
      return;
    }

    const request = fetch(`${API().apiURL}/${board}`);
    const response = await request;
    const res = await response.json();
    const urlBase = API().imageBase;

    //Format Pins on Development API
    let formattedPins = [];

    res.pins.forEach(pin => {

      if(pin.directory){
        let album = pin.files.map((subPin) => {
          subPin.src = 
            urlBase + 
            encodeURIComponent(pin.book) + 
            '/' + 
            encodeURIComponent(pin.name) + 
            '/' + 
            encodeURIComponent(subPin.subfile);
            subPin.book = pin.book;
            subPin.name = pin.name;
            return subPin;
        });
        pin.files = album;
      }
      
      pin.src = urlBase + encodeURIComponent(pin.book) + '/' + encodeURIComponent(pin.name);
      formattedPins.push(pin);
    });
    //console.log('newpins', formattedPins);
    setPins(formattedPins);
  }
  useEffect(() => {
    getPins();
  },[board]);

  const closeNav = async () => {
    props.setnavToggle(false);
  }

  let renderBooks;

  if((props.board.books && 
      props.board.books.length > 0 && 
      props.navToggle == true) || 
      (board === undefined && 
         props.board.books && 
         props.board.books.length > 0)){
    
    renderBooks = 
      <>
        <nav className={"clearfix"}>
        {props.board.books.map(obj => {

        let coverURL = `${API().imageBase}/${obj.hash}/cover`;
                  
        /*
        let img = new Image();
        img.onload = function () {
          this.handleImageLoaded();
        }
        img.src = coverURL;
        */
        
        /**
         *  {!this.props.imageState && <Skeleton height={200} width={200} />}
         *  {this.props.imageState && <img className="album-img" src={file.src} />}
         */
          return (
            
            <>
              <Link 
                key={`book-${obj.hash}`}
                onClick={closeNav}
                to={`/${obj.hash}`} 
                className={"board-link"} 
                style={{width: 125, height: 125, backgroundImage: `url("${coverURL}")`}}
              >
              <span className={"book-title"}>{obj.name}</span>
              </Link>
            </>
          )
        })}
        </nav>
      </>
  }

  return (
    <>
      <Header {...props} key="header"/>
      {renderBooks}
      {board !== undefined ? 
        <Board
          {...props}
          board={board} 
          key={board} 
          pins={pins}
          setPins={setPins}
        /> : 
        null
      }
      <Footer key="footer" />
      <Outlet />
    </>
  )
}

export default App;